import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const Release911 = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  const { stage } = useParams()

  useEffect(() => {
    release(stage, "911")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td>{
      `${icon}`
    }</td>  }

  return (
    <table className="data-table">
      <thead>
      <tr>
        <th><p>Market Language</p></th>
        <th><p>9921B2</p></th>
        <th><p>9923B2</p></th>
        <th><p>992142</p></th>
        <th><p>992342</p></th>
        <th><p>992442</p></th>
        <th><p>992542</p></th>
        <th><p>992642</p></th>
        <th><p>992830</p></th>
      </tr>
      </thead>
      <tbody>
      {releaseStatus.map((item, index) => {
        return <tr key={index}>
          <td>
            {item.market + "-" + item.language}
          </td>
          {modelAvailable(item.models.at(0).size)}
          {modelAvailable(item.models.at(1).size)}
          {modelAvailable(item.models.at(2).size)}
          {modelAvailable(item.models.at(3).size)}
          {modelAvailable(item.models.at(4).size)}
          {modelAvailable(item.models.at(5).size)}
          {modelAvailable(item.models.at(6).size)}
          {modelAvailable(item.models.at(7).size)}
        </tr>
      })}
      </tbody>
    </table>
  )
}

export default Release911
