const envs = {
  'dev': {
    backendBaseHost: 'mpi.pccompare.aws.porsche-preview.cloud',
    frontendBaseHost: 'operation.mpi.pccompare.aws.porsche-preview.cloud',
    stages: [
      { name: 'dev', display: 'Dev' },
      { name: 'preview', display: 'Preview' },
      { name: 'blue', display: 'Prod' },
    ],
    cognito: {
      clientId: '59je5vcuk8gvakc3ep1hct6sau',
      domain: 'ppn-mpi-operation.auth.eu-west-1.amazoncognito.com'
    },
    featureToggle: {
      vmceOn: true
    },
    awsAccountId: '113764269542',
  },
  'prod': {
    backendBaseHost: 'mpi.pccompare.aws.porsche.cloud',
    frontendBaseHost: 'operation.mpi.pccompare.aws.porsche.cloud',
    stages: [
      { name: 'ta', display: 'TA' }
    ],
    cognito: {
      clientId: '7dm9q4qo74mgbc4n9lrij04pga',
      domain: 'ppn-mpi-operation-prod.auth.eu-west-1.amazoncognito.com'
    },
    featureToggle: {
      vmceOn: false
    },
    awsAccountId: '594193977408',
  },
}

export const env = envs[process.env.REACT_APP_ENV ?? 'dev']
