import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const MacanRelease = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  const { stage } = useParams()

  useEffect(() => {
    release(stage, "taycan")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td>{
      `${icon}`
    }</td>  }

  return (
    <table className="data-table">
      <thead>
      <tr>
        <th><p>Market Language</p></th>
        <th><p>Y1AAI1</p></th>
        <th><p>Y1AFP1</p></th>
        <th><p>Y1AFT1</p></th>
        <th><p>Y1ADJ1</p></th>
        <th><p>Y1AFL1</p></th>
        <th><p>Y1AFM1</p></th>
        <th><p>Y1BBN1</p></th>
        <th><p>Y1BDJ1</p></th>
        <th><p>Y1BFL1</p></th>
        <th><p>Y1BFM1</p></th>
        <th><p>Y1CAI1</p></th>
        <th><p>Y1CDJ1</p></th>
        <th><p>Y1CFL1</p></th>
        <th><p>Y1CFM1</p></th>
      </tr>
      </thead>
      <tbody>
      {releaseStatus.map((item, index) => {
        return <tr key={index}>
          <td>
            {item.market + "-" + item.language}
          </td>
          {modelAvailable(item.models.at(0).size)}
          {modelAvailable(item.models.at(1).size)}
          {modelAvailable(item.models.at(2).size)}
          {modelAvailable(item.models.at(3).size)}
          {modelAvailable(item.models.at(4).size)}
          {modelAvailable(item.models.at(5).size)}
          {modelAvailable(item.models.at(6).size)}
          {modelAvailable(item.models.at(7).size)}
          {modelAvailable(item.models.at(8).size)}
          {modelAvailable(item.models.at(9).size)}
          {modelAvailable(item.models.at(10).size)}
          {modelAvailable(item.models.at(11).size)}
          {modelAvailable(item.models.at(12).size)}
          {modelAvailable(item.models.at(13).size)}
        </tr>
      })}
      </tbody>
    </table>
  )
}

export default MacanRelease
