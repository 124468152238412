import MarketList from './MarketList'
import { Outlet } from 'react-router-dom'

const Dashboard = () => {

  return <>
    <main className="d-flex flex-nowrap">
      <MarketList />
      <Outlet />
    </main>
  </>
}

export default Dashboard
