import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const MacanRelease = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  // Todo: fetch all markets which are available, store in marketLanguages
  const { stage } = useParams()

  useEffect(() => {
    release(stage, "macan")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td>{
      `${icon}`
    }</td>  }

  return (
    <table className="data-table">
      <thead>
      <tr>
        <th><p>Market Language</p></th>
        <th><p>Macan 4 Electric (XABBB1)</p></th>
        <th><p>Macan Turbo Electric (XABFD1)</p></th>
        <th><p>Macan Electric (XABAA1)</p></th>
        <th><p>Macan 4S Electric (XABDC1)</p></th>
      </tr>
      </thead>
      <tbody>
      {releaseStatus.map((item, index) => {
        return <tr key={index}>
          <td>
            {item.market + "-" + item.language}
          </td>
          {modelAvailable(item.models.at(0).size)}
          {modelAvailable(item.models.at(1).size)}
          {modelAvailable(item.models.at(2).size)}
          {modelAvailable(item.models.at(3).size)}
        </tr>
      })}
      </tbody>
    </table>
  )
}

export default MacanRelease
