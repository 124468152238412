import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const CayenneRelease = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  const { stage } = useParams()

  useEffect(() => {
    release(stage, "cayenne")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td>{
      `${icon}`
    }</td>  }

  return (
    <table className="data-table">
      <thead>
      <tr>
        <th><p>Market Language</p></th>
        <th><p>9YBAI1</p></th>
        <th><p>9YBAV1</p></th>
        <th><p>9YBBS1</p></th>
        <th><p>9YBBJ1</p></th>
        <th><p>9YBBN1</p></th>
        <th><p>9YBCT1</p></th>
        <th><p>9YBCZ1</p></th>
        <th><p>9YBCP1</p></th>
        <th><p>9YAAI1</p></th>
        <th><p>9YAAV1</p></th>
        <th><p>9YABS1</p></th>
        <th><p>9YABJ1</p></th>
        <th><p>9YABN1</p></th>
        <th><p>9YACT1</p></th>
      </tr>
      </thead>
      <tbody>
      {releaseStatus.map((item, index) => {
        return <tr key={index}>
          <td>
            {item.market + "-" + item.language}
          </td>
          {modelAvailable(item.models.at(0).size)}
          {modelAvailable(item.models.at(1).size)}
          {modelAvailable(item.models.at(2).size)}
          {modelAvailable(item.models.at(3).size)}
          {modelAvailable(item.models.at(4).size)}
          {modelAvailable(item.models.at(5).size)}
          {modelAvailable(item.models.at(6).size)}
          {modelAvailable(item.models.at(7).size)}
          {modelAvailable(item.models.at(8).size)}
          {modelAvailable(item.models.at(9).size)}
          {modelAvailable(item.models.at(10).size)}
          {modelAvailable(item.models.at(11).size)}
          {modelAvailable(item.models.at(12).size)}
          {modelAvailable(item.models.at(13).size)}
        </tr>
      })}
      </tbody>
    </table>
  )
}

export default CayenneRelease
