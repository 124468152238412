import { useEffect, useState } from 'react'
import { release } from "./BffClient";
import { useParams } from "react-router-dom";

const PanameraRelease = () => {

  const [ releaseStatus, setReleaseStatus ] = useState([])

  const { stage } = useParams()

  useEffect(() => {
    release(stage, "panamera")
      .then(setReleaseStatus)
  }, [ stage ])

  const modelAvailable = size => {
    const icon = size > 0 ? '✅' : '⛔️'
    return <td>{
      `${icon}`
    }</td>  }

  return (
    <table className="data-table">
      <thead>
      <tr>
        <th><p>Market Language</p></th>
        <th><p>YAADG1</p></th>
        <th><p>YAAFH1</p></th>
        <th><p>YABFH1</p></th>
      </tr>
      </thead>
      <tbody>
      {releaseStatus.map((item, index) => {
        return <tr key={index}>
          <td>
            {item.market + "-" + item.language}
          </td>
          {modelAvailable(item.models.at(0).size)}
          {modelAvailable(item.models.at(1).size)}
          {modelAvailable(item.models.at(2).size)}
        </tr>
      })}
      </tbody>
    </table>
  )
}

export default PanameraRelease
