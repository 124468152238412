import { useParams } from 'react-router-dom'
import { preSignUrl } from './BffClient'
import { env } from './env'

export const MarketTableBody = ({ models }) => {

  const { stage } = useParams()

  const empty = () => <td/>

  const size = model => {
    const size = model.size
    if (!size) {
      return <p/>
    }

    return size >= 1000 ? <p>{Math.round(size / 1000)} kB</p> : <p>{size} B</p>
  }

  const datetime = model => {
    const lastModified = model.lastModified
    if (!lastModified) {
      return <p/>
    }
    const splits = lastModified.split('T')
    return (
      <p>
        {splits[0].substring(2)}<br/>
        {splits[1].split('.')[0]}
      </p>
    )
  }

  function modelTypeYearCell(model) {
    return <td className="cell-model-id">
      <p>{model.modelType}</p>
      <p>{model.modelYear}</p>
      <p>{model.engineType}</p>
    </td>
  }

  const popupInfo = (displayText, popups) =>
    <p className="additional-info">
      {displayText}
      {!!popups.length && <span className="additional-info-text">
      {
        popups.map(p => <span key={p}>{p}<br/></span>)
      }
    </span>}
    </p>

  const openPccd = ({ market, language, modelType, modelYear }) => {
    const bucket = `pccd-car-model-${stage}-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open PCCD S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openDomain = ({ market, language, modelType, modelYear }) => {
    const bucket = `car-models-${stage}-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open Domain S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPresentationModel = ({ market, language, modelType, modelYear }) => {
    const bucket = `market-${stage}-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/models/${modelType}-${modelYear}`
    const confirm = window.confirm(`Open Presentation Model S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openExtension = ({ market, language, modelType, modelYear }, source) => {
    const bucket = `car-model-extension-${stage}-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${modelType}-${modelYear}/${source}`
    const confirm = window.confirm(`Open Extension ${source} S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPresentation = ({ market, language }, file) => {
    const bucket = `market-${stage}-${env.awsAccountId}-eu-west-1`
    const key = `${market}-${language}/${file}`
    const confirm = window.confirm(`Open Presentation ${file} S3 for ${key}?`)
    confirm && openPage({ bucket, key })
  }

  const openPage = ({ bucket, key }) =>
    preSignUrl(bucket, key)
      .then(window.open)

  const modelCell = (className, model, onOpen) => {
    const clickable = onOpen ? 'clickable' : ''
    return (
      model ?
        <td onClick={onOpen} className={`${className} ${clickable}`}>
          {datetime(model)}
          {size(model)}
        </td>
        : empty()
    )
  }

  const priceCell = model => {
    const price = model?.price
    const icon = price?.formattedValue ? '✅' : '⛔️'
    return <td className="cell-model-present">{
      price && popupInfo(`${icon} Price`, [
        price.currencyCode,
        price.formattedValue,
        price.value,
      ])
    }</td>
  }

  const ecoCell = model => {
    return (
      <td className="cell-model-present">
        {ecoStandard('WLTP', model?.wltpKeys)}
        {ecoStandard('NEDC', model?.nedcKeys)}
        {ecoStandard('ECE', model?.eceKeys)}
      </td>
    )
  }

  const ecoStandard = (name, keys) => {
    const icon = keys?.length ? '✅' : '⛔️'
    return !!keys.length && popupInfo(`${icon}${name} ${keys.length || ''}`, keys)
  }

  const techCell = techDetail => {
    const icon = techDetail?.complete ? '✅' : '⛔️'
    return <td className="cell-model-present">
      {techDetail && popupInfo(`${icon}TDetail ${techDetail.fields?.length}`, techDetail.fields)}
    </td>
  }

  const ecoGroupCell = (model, onOpen) => {
    const ecoGroup = model?.ecoGroup
    return <td onClick={onOpen} className="cell-model-present">
      {ecoGroup && popupInfo('✅ EGroup', [ ecoGroup ])}
    </td>
  }

  const modelStructure = (model, onOpen) => {
    const range = model?.range
    const series = model?.series
    return <td onClick={onOpen} className="cell-model-present">
      {range && popupInfo('✅ Range', [ range ])}
      {series && popupInfo('✅ Series', [ series ])}
    </td>
  }

  const rangeSeriesCells = (rangeSeries, modelName) => {
    return <td className="cell-model-id">
      <p>{rangeSeries?.range ?? ''}</p>
      <p>{rangeSeries?.series ?? ''}</p>
      <p>{modelName ?? ''}</p>
    </td>
  }

  const availability = (invisible, pressEmbargo, isMpiHidden, manualEnabled, pccd) => {
    const today = new Date().getTime();
    const modelPublishDate = new Date(pressEmbargo + "T00:00:00").getTime();
    const isAfterBlockingDate = today >= modelPublishDate || pressEmbargo === undefined || pressEmbargo === null;
    const iccVisible = !(invisible)
    const mpiAvailable = !(isMpiHidden || null == isMpiHidden)

    let icon = (isAfterBlockingDate && iccVisible && mpiAvailable && pccd !== null) ? '✅' : '⛔️'

    if (stage === "ta") {
      icon = (mpiAvailable && pccd !== null) ? '✅' : '⛔️'
    }

    if (stage !== "ta" && isAfterBlockingDate && manualEnabled && pccd !== null) {
      icon = '✅'
    }

    return <td className="cell-model-id">{
      icon && popupInfo(`${icon} available`, [
        `iccVisible: ${iccVisible}`,
        `pressEmbargo: ${pressEmbargo}`,
        `mpiHidden: ${isMpiHidden}`
      ])
    }</td>
  }

  return (
    <tbody>
    {
      models
        .sort((x, y) => y.modelYear - x.modelYear)
        .sort((x, y) => (y.series + y.range) - (x.series + x.range))
        .map(model => {
          return (
            <tr key={model.id}>
              {modelTypeYearCell(model)}
              {rangeSeriesCells(model.structure, model.modelName)}
              {modelCell('cell-model-id', model.prototype)}
              {availability(model.iccInvisible, model.pressEmbargo, model.isMpiHidden, model.isManualActivated, model.pccd)}
              {modelCell('cell-model-source', model.pccd, () => openPccd(model))}
              {modelCell('cell-model-source', model.extensionPs5, () => openExtension(model, 'ps5'))}
              {modelCell('cell-model-source', model.extensionSitecore, () => openExtension(model, 'sitecore'))}
              {modelCell('cell-model-source', model.extensionSitecorePull, () => openExtension(model, 'sitecore-pull'))}
              {modelCell('cell-model-domain', model.domain, () => openDomain(model))}
              {modelCell('cell-model-present', model.presentSingle, () => openPresentationModel(model))}
              {modelCell('cell-model-present', model.presentList, () => openPresentation(model, 'models'))}
              {priceCell(model.presentPrice)}
              {ecoCell(model.presentEcoDetail)}
              {techCell(model.presentTechDetail)}
              {techCell(model.presentTechDetailS)}
              {ecoGroupCell(model.ecoGroup, () => openPresentation(model, 'eco-groups'))}
              {modelStructure(model.structure, () => openPresentation(model, 'structure'))}
            </tr>
          )
        })
    }
    </tbody>
  )
}
