import {getIdToken, login} from './PPNCognitoService'
import axios from 'axios'

const httpRequest = config =>
  axios.request(config)
    .catch(async error => {
      if (error.response.status === 401) {
        await login(window.location.pathname)
      } else {
        throw error
      }
    })


export async function fetchBff(stage, market, language) {
  const config = {
    method: 'get',
    url: '/dashboard',
    params: { stage, market, language },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(config)
    .then(response => response.data)
}

export async function fetchBffMarkets(stage) {
  const config = {
    method: 'get',
    url: '/dashboard/markets',
    params: { stage },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(config)
    .then(response => response.data)
}

export async function preSignUrl(bucket, key) {
  const config = {
    method: 'get',
    url: '/s3-pre-signed-url',
    params: { bucket, key },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(config)
    .then(response => response.data)
}

export async function fetchVersions(stage) {
  const config = {
    method: 'get',
    url: '/versions',
    params: { stage },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(config)
    .then(response => response.data)
}

export async function diffVersions(stage, { objectKey, latter, former }) {
  const config = {
    method: 'get',
    url: '/versions',
    params: { stage, objectKey, latter, former },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(config)
    .then(response => response.data)
}

export async function fetchVmceDashboardData(stage) {
  const config = {
    method: 'get',
    url: '/vmce-dashboard',
    params: { stage },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(config)
    .then(response => response.data)
}

export async function release(stage, range) {
  const result = {
    method: 'get',
    url: '/release',
    params: { stage, range },
    headers: {
      'Authorization': `Bearer ${getIdToken()}`,
    }
  }
  return httpRequest(result)
    .then(response => response.data)
}
