import HomePage from './HomePage'
import { useParams } from 'react-router-dom'

const Stage = () => {

  const { stage } = useParams()

  return <>
    <HomePage stage={stage} />
  </>
}

export default Stage
