import {useEffect, useState} from "react";
import "./Authredirect.css"
import {redeemCodeForTokens} from "./PPNCognitoService";

const AuthRedirect = () => {
    const [message, setMessage] = useState("Verifying authentication token...")

    useEffect(() => {
        const args = new URLSearchParams(window.location.search);
        const code = args.get("code");
        const error = args.get("error");
        if (code) {
            redeemCodeForTokens(code, (error) => {
                setMessage("Auth failed." + JSON.stringify(error.response.data))
            });
        } else if (error) {
            const errorDescription = args.get("error_description");
            setMessage(errorDescription || "Authentication failed.")
        }
    }, []);
    return (<div className="container"><h2 className="center">{message}</h2></div>)
}

export default AuthRedirect;