import { useEffect, useState } from 'react'
import { diffVersions, fetchVersions } from './BffClient'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import './Versions.css'

const Versions = () => {

  const { stage } = useParams()

  const [ markets, setMarkets ] = useState([])

  useEffect(() => {
    fetchVersions(stage)
      .then(setMarkets)
  }, [ stage ])

  const baseVersionMark = ({ lastModified, versionId }) => {
    const time = moment(lastModified)
    return <div key={versionId} className="col-1">
      <p className="m-2 base-version-mark">
        {time.format(`ddd, MMM D`)}<br />
        {time.format(`HH:mm`)}
      </p>
    </div>
  }

  const followingVersionMark = (objectKey, { lastModified, versionId, previousVersionId }) => {
    const time = moment(lastModified).local()
    return <div onClick={e => {
      diffVersions(stage, { objectKey, latter: versionId, former: previousVersionId })
        .then(diff => {
          if (diff.former.hash === diff.latter.hash) {
            console.log('identical', objectKey)
          } else {
            console.log(diff)
          }
        })
    }} key={versionId} className="col-1">
      <p className="m-2 version-mark">
        {time.format(`ddd, MMM D`)}<br />
        {time.format(`HH:mm`)}
      </p>
    </div>
  }

  const versionsChain = (objectKey, versions) => {
    const orderedVersions = [ ...versions ].reverse()
    for (let i = 1; i < orderedVersions.length; i++) {
      orderedVersions[i].previousVersionId = orderedVersions[i - 1].versionId
    }

    return <>
      {orderedVersions.slice(1).map(version => followingVersionMark(objectKey, version))}
    </>
  }

  return <main className="container-fluid scrollarea">
    {
      markets.map(market =>
        <div className="row mb-md-2" key={market.objectKey}>
          <span className="text-end col-1 me-2">
            {market.objectKey.split('/models')[0]}
          </span>
          {baseVersionMark(market.versions[market.versions.length - 1])}
          {versionsChain(market.objectKey, market.versions)}
        </div>
      )
    }
  </main>
}

export default Versions
