import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { fetchVmceDashboardData, preSignUrl } from './BffClient'
import { env } from './env'
import './Vmce.css'

const VmceDashboard = () => {

  const { stage } = useParams()

  const [ vmce, setVmce ] = useState([])

  const [ metadata, setMetadata ] = useState({})

  useEffect(() => {
    fetchVmceDashboardData(stage)
      .then(response => {
        if (!response.metadata) {
          setVmce(response)
        } else {
          setVmce(response.contents)
          setMetadata(response.metadata)
        }
      })
  }, [ stage ])

  const openPage = ({ bucket, key }) =>
    preSignUrl(bucket, key)
      .then(window.open)

  const openContent = key => {
    const bucket = `vmce-node-${stage}-${env.awsAccountId}-eu-west-1`
    const confirm = window.confirm(`Open VMCE content for "${key}"?`)
    confirm && openPage({ bucket, key })
  }

  const updating = () => metadata.numberOfVisibleMessages && metadata.numberOfVisibleMessages !== '0'

  const contentStatus = () => {
    return updating() ?
      <span className="vmce-status-updating">Updating</span> :
      <span className="vmce-status-uptodate">UpToDate</span>
  }

  const etf = () => metadata.numberOfVisibleMessages / 75 | 0

  return <>
    <div className="vmce-status">
      <h3>VMCE Content Status: {contentStatus()}</h3>
      {updating() && <h5>Expect to finish in {etf()} minutes</h5>}
    </div>
    <table className="data-table">
      <thead>
      <tr>
        <th className="col-2"><p>LanguageCode</p></th>
        <th className="col-4"><p>UpdatedTime</p></th>
      </tr>
      </thead>
      <tbody>
      {
        vmce.map(v =>
          <tr key={v.Key}>
            <td className="clickable" onClick={() => openContent(v.Key)}><p>{v.Key.replace('vmce/', '')}</p></td>
            <td><p>{v.LastModified}</p></td>
          </tr>
        )
      }
      </tbody>
    </table>
  </>

}

export default VmceDashboard
